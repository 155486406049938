import AbstractMapper from '@src/mappers/AbstractMapper'
import { PeriodMapper } from '@src/mappers/PeriodMapper'
import { type MetaData, BookingStatus, type Time } from '@src/models/MetaData'
import { type MetaDataDto } from '@superbet-group/offer.clients.lib'


const periodMapper: PeriodMapper = new PeriodMapper()
export class MetaDataMapper extends AbstractMapper<MetaDataDto, MetaData> {
    createTargetObject(dto: MetaDataDto) {
        const metaData: MetaData = {
            team1Score: dto.homeTeamScore ? parseInt(dto.homeTeamScore, 10) : undefined,
            team2Score: dto.awayTeamScore ? parseInt(dto.awayTeamScore, 10) : undefined,
            minute: dto.minutes ? Number(dto.minutes) : undefined,
            periodStatus: dto.periodStatus || '',
            periods: dto.periods ? periodMapper.map(dto.periods, true) : undefined,
            bookingStatus: (dto.bookingStatus as BookingStatus) || undefined,
            team1GemScore: dto.homeTeamGameScore,
            team2GemScore: dto.awayTeamGameScore,
            serve: dto.teamServing,
            status: dto.status,
        }

        if (dto.stoppageTime) {
            metaData.stoppageTime = parseTimeString(dto.stoppageTime);
             // there is also stoppageTimeAnnounced but we don't use it

        }
        metaData.t1YCrd = dto.homeTeamYellowCards
        metaData.t2YCrd = dto.awayTeamYellowCards
        metaData.t1RCrd = dto.homeTeamRedCards
        metaData.t2RCrd = dto.awayTeamRedCards
        metaData.t1Crn = dto.homeTeamCorners
        metaData.t2Crn = dto.awayTeamCorners

        return metaData;
    }
}


// time is on format mm:ss
function parseTimeString(time: string): Time {
    const [minutes, seconds] = time.split(':').map((x) => parseInt(x, 10))

    return {
        minutes,
        seconds
    }
}
