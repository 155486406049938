export enum MarketAttribute {
    main = 'preselected'
}

export enum OfferState {
    prematch = 'prematch',
    live = 'live'
}

export enum OddStatus {
    active = 'active',
    block = 'block'
}

export enum MatchTags {
    superLive = 'superLive'
}
