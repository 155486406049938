<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useLiveOfferStore } from '@src/stores/offer'
import MarketOdds from '@src/components/MarketOdds.vue'
import type Match from '@src/models/Match'
import { type DisplayOdds } from '@src/stores/types'
import { getSportSectionId } from '@src/helpers/helpers'

const store = useLiveOfferStore()
const { displayMarkets } = storeToRefs(store)

const props = defineProps<{
    sportSectionId: string
    event: Readonly<Match>
}>()

// Find & group odds for an event's market using displayMarkets
const marketOdds = (event: Readonly<Match>, marketId: number): DisplayOdds => {
    const displayOdds: DisplayOdds = { odds: [], specialBetValue: '' }

    const sportUniqueId = getSportSectionId(event.sportId, event.isSuperLive)
    const sportMarkets = displayMarkets.value[sportUniqueId]
    const market = sportMarkets.find((market) => market.marketId === marketId)

    if (market) {
        market.odds.forEach((marketOdd) => {
            const odd =
                event.odds.find(
                    ({ marketId, id: oddId }) =>
                        marketId === market.marketId && oddId === marketOdd?.id
                ) || null

            displayOdds.odds.push(odd)

            if (odd && !displayOdds.specialBetValue && odd.specialBetValue) {
                displayOdds.specialBetValue = odd.specialBetValue
            }
        })
    }

    return displayOdds
}
</script>

<template>
    <template v-if="displayMarkets[props.sportSectionId]">
        <div
            v-for="market in displayMarkets[props.sportSectionId]"
            :key="market.marketId"
            class="event-market"
        >
            <MarketOdds :market-odds="marketOdds(props.event, market.marketId)" />
        </div>
    </template>
    <div v-else class="no-bets">{{ $t('No Bets') }}</div>
</template>

<style scoped lang="scss">
.event-market {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    flex: 0 0 13.79%;
    max-width: 13.79%;
    height: $table-row-odds-height;
    border-radius: $table-row-odds-radius;
    background: $table-row-odds-background;
    padding: 0 $space-l;
    margin: $space-sm;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.no-bets {
    margin: auto;
    opacity: 25%;
    font-size: $font-size-lg;
}
</style>
