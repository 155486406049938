/* eslint-disable sort-keys */
import { keyBy as _keyBy, isEmpty as _isEmpty } from 'lodash'
import Struct from '@src/models/struct/Struct'
import {
    SportMapper,
    TournamentMapper,
    CategoryMapper,
    MarketTreeMapper,
    OddTypeMapper
} from '@src/mappers/StructMappers'
import { RestService } from '@superbet-group/offer.clients.lib'
import { getOfferLocaleLangForEnv } from '@src/helpers/helpers'
import ms from 'ms'

class Mappers {
    sportMapper?: SportMapper = new SportMapper()
    tournamentMapper?: TournamentMapper = new TournamentMapper()
    categoryMapper?: CategoryMapper = new CategoryMapper()
    oddTypeMapper?: OddTypeMapper = new OddTypeMapper()
    marketTreeMapper?: MarketTreeMapper = new MarketTreeMapper()
}

interface Singleton<T> {
    getInstance(): T
    createInstance?(struct: Struct): T
}

class StructService {
    private static instance?: StructService
    private restService: RestService
    private mappers = new Mappers()

    private constructor() {
        this.restService = new RestService(import.meta.env.VITE_APP_OFFER_HOST_URL, getOfferLocaleLangForEnv(), ms('30s'), 3)
    }

    public static getInstance(): StructService {
        if (!StructService.instance) {
            StructService.instance = new StructService()
        }

        return StructService.instance
    }

    /**
     * @throws {RequestError}
     * @throws {MalformedDataError}
     * @throws {Error}
     */
    public async getStruct(): Promise<Struct> {
            const data = await this.restService.getStruct()

            const { tournaments, sports, outcomes: oddTypes, categories, marketTree } = data
            const hasEmptyValues: boolean = [
                tournaments,
                sports,
                oddTypes,
                categories,
                marketTree
            ].some((value) => _isEmpty(value))

            if (hasEmptyValues) {
                console.error('Struct has empty structures')
            }

            const struct = {
                tournaments: _keyBy(this.mappers.tournamentMapper!.map(tournaments, true), 'id'),
                sports: _keyBy(this.mappers.sportMapper!.map(sports, true), 'id'),
                oddTypes: _keyBy(this.mappers.oddTypeMapper!.map(oddTypes, true), 'id'),
                categories: _keyBy(this.mappers.categoryMapper!.map(categories, true), 'id'),
                marketTree: _keyBy(this.mappers.marketTreeMapper!.map(marketTree, true), 'sportId'),
                markets: {},
            } as Struct

            return struct
    }
}

export interface IStructService extends StructService {}

export default StructService as Singleton<StructService>
