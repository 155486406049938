import { Subscription } from 'rxjs'

interface EventSubscriptions {
    subscriptions: Record<number, Subscription>
    addSubscription(eventId: number, subscription: Subscription): void
    getSubscriptionIds(): string[]
    removeSubscription(eventId: number): void
    hasSubscription(eventId: number): boolean
}

export const Subscriptions: EventSubscriptions = {
    subscriptions: {},
    addSubscription(eventId: number, subscription: Subscription) {
        if (eventId && !this.hasSubscription(eventId)) {
            this.subscriptions[eventId] = subscription
        }
    },
    getSubscriptionIds() {
        return Object.keys(this.subscriptions);
    },
    hasSubscription(eventId: number): boolean {
        return !!this.subscriptions[eventId]
    },
    removeSubscription(eventId: number) {
        if (this.hasSubscription(eventId)) {
            this.subscriptions[eventId].unsubscribe()
            delete this.subscriptions[eventId]
        }
    }
}
