<script setup lang="ts">
import { computed } from 'vue'
import OddTracker from '@src/components/OddTracker.vue'
import { type DisplayOdds } from '@src/stores/types'
import { NUMBER_OF_VISIBLE_ODDS_FOR_SPECIFIER } from '@src/constants/constants'

const props = defineProps<{
    marketOdds: DisplayOdds
}>()

const oddSpecifier = computed(() => {
    const { odds, specialBetValue } = props.marketOdds

    // Display specifier if market has 2 odds
    if (specialBetValue && odds.length === NUMBER_OF_VISIBLE_ODDS_FOR_SPECIFIER) {
        return specialBetValue
    }

    return ''
})

const hasAtLeastOneOdd = computed(() => {
    return props.marketOdds.odds.some((odd) => odd)
})
</script>

<template>
    <template v-if="hasAtLeastOneOdd">
        <template v-for="(odd, index) in marketOdds.odds" :key="index">
            <OddTracker v-if="odd" :odd="odd" :style="`order: ${index};`" />
            <span v-else class="odd font-number" :style="`order: ${index};`">-</span>
        </template>
        <span v-if="oddSpecifier" class="odd__specifier font-number">{{ oddSpecifier }}</span>
    </template>
    <span v-else class="odd--empty font-number">{{ $t('No Odds') }}</span>
</template>

<style lang="scss" scoped>
.odd {
    color: $orange;
    flex: 0 0 27.92%;
    padding: 0 $space-s;
    text-align: center;
    position: relative;

    &--down {
        color: $brand-red;
    }

    &--up {
        color: $green;
    }

    &__specifier {
        order: 0;
        font-size: $font-size-m;
        font-weight: initial;
        text-transform: uppercase;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &--empty {
        opacity: 25%;
    }
}
</style>
