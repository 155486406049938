import {
    initFeatureToggleService,
    type FeatureToggleService,
    type SubscriptionOptions
} from '@superbet-group/web.lib.feature-toggle-service';
import {FeatureFlagsDefaultValues } from './Flags';


class FlagsService {
    private static instance: FlagsService;
    private featureFlagsService: FeatureToggleService;

    public constructor() {
        const flagsClientID = import.meta.env.VITE_APP_LAUNCH_DARKLY_CLIENT_ID;
       
        this.featureFlagsService = initFeatureToggleService(flagsClientID, {
            isDevelopment: import.meta.env.DEV,
            platformId: 'retail.offer-display.live'
        });

    }

    public static getInstance(): FlagsService {
        if (!FlagsService.instance) {
            FlagsService.instance = new this();
        }

        return FlagsService.instance;
    }

    public async getFeatureFlag<T>(flag: string) {
        return await this.featureFlagsService.getFeatureValue<T>(
            flag,
            FeatureFlagsDefaultValues[flag]
        );
    }

    public async subscribeToFeatureFlagChange(
        flag: string,
        callback,
        options?: SubscriptionOptions<boolean>
    ) {
        return await this.featureFlagsService.subscribeToFeatureChanges(
            flag,
            callback,
            FeatureFlagsDefaultValues[flag],
            options
        );
    }
}

export default FlagsService;
