import Odd from '@src/models/Odd'
import { type OddDto } from '@superbet-group/offer.clients.lib'
import AbstractMapper from './AbstractMapper'

export class OddMapper extends AbstractMapper<OddDto, Odd>{
    createTargetObject(dto: OddDto): Odd {
        const odd: Odd = new Odd()
        
        odd.uuid = dto.uuid
        odd.marketUuid = dto.marketUuid
        odd.id = dto.outcomeId
        odd.value = dto.price
        odd.marketId = dto.marketId
        odd.marketTag = dto.marketTags
        odd.status = dto.status || ''
        odd.marketOrder = dto.marketGroupOrder
        odd.offerType = dto.offerStateId
        odd.showSpecialBetStatus = dto.showSpecialBetValue
        odd.tags = dto.tags || ''
        odd.code = dto.code
        odd.specialBetValue = dto.specialBetValue || ''
        odd.name = dto.name || ''
        odd.specifiers = dto.specifiers

        odd.initialMarketName = dto.marketName
        odd.description = dto.info
    
        return odd
    }
}

