<script setup lang="ts">
import EventDetails from '@src/components/EventDetails.vue'
import EventMarket from '@src/components/EventMarket.vue'
import type Match from '@src/models/Match'

const props = defineProps<{
    event: Readonly<Match>
    sportSectionId: string
}>()
</script>

<template>
    <div class="event-code">{{ event.code }}</div>
    <EventDetails
        :has-sets="event.hasSets"
        :meta-data="event.metaData"
        :match-status="event.matchStatus"
        :team1-name="event.team1Name"
        :team1-score="event.team1Score"
        :team2-name="event.team2Name"
        :team2-score="event.team2Score"
    />
    <EventMarket :event="event" :sport-section-id="props.sportSectionId" />
</template>

<style scoped lang="scss">
.event-code {
    font-weight: $font-weight-boldest;
    flex: 0 0 3.34%;
    max-width: 3.34%;
    margin-left: $space-l;
    text-align: left;
}
</style>
