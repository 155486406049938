export const datetimeFormats = {
    'ro': {
      weekday: {
        weekday: 'long'
      },
      date: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      time: {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: "h23"
      }
    }
} as const;