import Odd from '@src/models/Odd'

export default class Struct {
    sports: Record<number, Sport>
    tournaments: Record<number, Tournament>
    categories: Record<number, Category>
    markets: Record<number, MarketSimple>
    oddTypes: Record<number, OddType>
    marketTree: Record<number, MarketTreeGroup>
}

export interface MarketSimple {
    id: number
    uniqueId?: string
    name: string
}

export class Market implements MarketSimple {
    id: number
    uniqueId?: string
    originalName: string
    name: string
    odds: Odd[] = []
    order: number
    tags: string

    constructor({
        id,
        name,
        order,
        odds = [],
        tags = '',
        uniqueId = '',
        originalName = ''
    }: {
        id: number
        name: string
        order: number
        odds?: Odd[]
        tags?: string
        uniqueId?: string
        originalName?: string
    }) {
        this.id = id
        this.name = name
        this.odds = odds
        this.order = order
        this.tags = tags
        this.uniqueId = uniqueId
        this.originalName = originalName
    }

    get isMain() {
        return this.odds[0] && this.odds[0].isMain()
    }

    public containsAttribute(attribute: string) {
        return this.tags.includes(attribute)
    }
}

export class Category {
    id: number
    name: string
    order?: number
}

export class OddType {
    id: number
    // egalitate, victoria primului jucător, etc.
    description: string
    // symbol is X, 1, 2
    symbol: string
}

export class Sport {
    id: number
    name: string
    eventCount = 0
    order?: number
}

export class Tournament {
    id: number
    name: string
    order?: number
    orderPromoted?: number
    footer?: string
}

export class MarketTreeGroup {
    sportId: number
    markets: Record<number, MarketGroup>
}

export class MarketGroup {
    marketId: number
    odds: number[]
}
