<script setup lang="ts">
import { ref, watch, computed, onBeforeUnmount } from 'vue'
import type Odd from '@src/models/Odd'
import { ODD_CHANGE_TRACKER_TIMEOUT } from '@src/constants/constants'

const props = defineProps<{
    odd: Odd
}>()

const oddChangeClass = ref('')
let oddChangedTimer: NodeJS.Timeout | null = null

const setOddChangeClass = (value: string) => {
    oddChangeClass.value = value
}

const oddDisplayValue = computed(() => {
    if (props.odd.isBlocked) {
        setOddChangeClass('')

        return '-'
    }

    return props.odd.value.toFixed(2)
})

watch(
    () => props.odd.value,
    (newValue, oldValue) => {
        if (oldValue !== newValue) {
            const oddChangedClass = oldValue > newValue ? 'down' : 'up'

            setOddChangeClass(oddChangedClass)
            oddChangedTimer = setTimeout(() => setOddChangeClass(''), ODD_CHANGE_TRACKER_TIMEOUT)
        }
    }
)

onBeforeUnmount(() => {
    if (oddChangedTimer) {
        clearTimeout(oddChangeClass.value)
    }
})
</script>

<template>
    <span :class="['font-number', 'odd', oddChangeClass && `odd--${oddChangeClass}`]">
        {{ oddDisplayValue }}
        <i :class="`superbet-icon icon-chevron_${oddChangeClass}_right`" />
    </span>
</template>

<style lang="scss" scoped>
.superbet-icon {
    font-size: $font-size-s;
    position: absolute;
}

.icon-chevron_down_right {
    bottom: -$space-s;
}

.icon-chevron_up_right {
    top: -$space-s;
}
</style>
