import { createI18n } from 'vue-i18n'
import { APP_LANG } from '@src/constants/constants'
import roMessages from '@src/i18n/locales/ro-RO.json'
import { datetimeFormats } from '@src/i18n/formats';

const i18n = createI18n({
    datetimeFormats,
    legacy: false,
    globalInjection: true,
    fallbackLocale: 'ro',
    locale: APP_LANG,
    missingWarn: false,
    messages: {
        ro: roMessages
    }
})

export default i18n
