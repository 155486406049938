import { FeatureFlags } from '@src/services/flags/Flags';
import FlagsService from '@src/services/flags/FlagsService';
import { NetworkClientMetrics, ClientResponse } from '@superbet-group/multiplatform.lib';

type WhitelistHosts = Record<string, number>;

type Metric = {
    whitelist_hosts: WhitelistHosts;
    type: string;
    name: string;
    id: number;
    buckets_ms: number[];
};

type ClientMetricsConfig = {
    push_interval_ms: number;
    metrics: Metric[];
};

type NetworkClientResponse = InstanceType<typeof ClientResponse>;

// just for getting intellisense to work since not all browsers support responseStatus
type PerformanceResourceTimingWithStatus = PerformanceResourceTiming & {
    responseStatus: number;
};

const ClientMetrics = new NetworkClientMetrics();

const networkObserver = new PerformanceObserver(performanceHandler);
const clientMetricsUrl = import.meta.env.VITE_APP_CLIENT_METRICS_URL;

async function getClientMetricsConfig() {
    try {
        const configResponse = await fetch(`${ clientMetricsUrl }/config`);
        const clientMetricsConfig: ClientMetricsConfig = await configResponse.json();
        const networkConfig = clientMetricsConfig.metrics.find(
            (item) => item.name === 'client_http_duration'
        );

        return {
            networkConfig,
            pushInterval: clientMetricsConfig.push_interval_ms
        };
    } catch {
        // don't do anything if metric config fails to load
        return null;
    }
}

function startNetworkTracking(config: Metric, sendInterval: number) {
    ClientMetrics.configure(
        config.id,
        JSON.stringify(config.buckets_ms),
        JSON.stringify(config.whitelist_hosts)
    );
    networkObserver.observe({ entryTypes: ['resource'] });
    window.setInterval(sendClientNetworkMetrics, sendInterval);
}

function addMetric(entry: NetworkClientResponse) {
    ClientMetrics.addMetric(entry);
}

function getClientMetricsMetaHeaders() {
    const countries = {
        ['ro']: 'Romania',
        ['hr']: 'Croatia'
    };
    const platform = 'desktop-retail-tv';
    const app = `retail.offer-display.live`;
    const country = countries[import.meta.env.VITE_APP_COUNTRY];
    const version = import.meta.env.VITE_APP_VERSION.slice(1);

    return `${ platform }/${ app }/${ country }/${ version }`;
}

// get the metrics from the lib, send and clear the lib
function sendClientNetworkMetrics() {
    const metricsToSend = ClientMetrics.getMetricsRaw();

    if (metricsToSend) {
        // fire and forget
        fetch(`${ clientMetricsUrl }/instrument`, {
            method: 'post',
            headers: {
                'Content-Type': 'text/plain',
                'client-metrics-meta': getClientMetricsMetaHeaders()
            },
            body: metricsToSend
        });

        ClientMetrics.clearMetrics();
    }
}

function performanceHandler(entryList: PerformanceObserverEntryList) {
    const entries = getPerformanceEntries(entryList);

    if (entries.length) {
        const clientResponses = prepareClientResponses(entries);

        clientResponses.forEach(addMetric);
    }
}

// filters out events that are api calls - we don't need assets
function getPerformanceEntries(list: PerformanceObserverEntryList) {
    const initiatorTypeFilters = ['xmlhttprequest', 'fetch', 'other']; //other is eventsource, after we migrate to offer lib it will be covered by fetch

    const entries = list.getEntries() as PerformanceResourceTimingWithStatus[];

    return entries.filter((entry) => {
        //This won't work on Firefox & Safari as it doesn't support responseStatus from PerformanceResourceTiming
        // Same behavior as RBA, accept all entries. After we migrate RBA to support response status we can add the filter back
        return initiatorTypeFilters.includes(entry.initiatorType); //&& entry.responseStatus;
    });
}

// maps performance entries to our custom lib model
function prepareClientResponses(
    entries: PerformanceResourceTimingWithStatus[]
): NetworkClientResponse[] {
    return entries.map((entry) => {
        const url = new URL(entry.name);

        return new ClientResponse(
            Math.ceil(entry.duration),
            url.host,
            'unknown',
            url.pathname,
            entry.responseStatus
        );
    });
}

async function initializeNetworkAnalytics() {
    const areNetworkMetricsEnabled = await FlagsService.getInstance().getFeatureFlag<boolean>(
        FeatureFlags.networkAnalytics
    );

    if (areNetworkMetricsEnabled) {
        const metricsConfig = await getClientMetricsConfig();

        if (metricsConfig?.networkConfig) {
            startNetworkTracking(metricsConfig.networkConfig, metricsConfig.pushInterval);
        }
    }
}

export { initializeNetworkAnalytics };
