import { has as _has } from 'lodash-es'
import { COMPETITOR } from '@src/constants/constants'
import i18n from '@src/i18n'

/**
 * Specifiers are a way to display dynamic names for bet groups or odd types.
 * However, we need them to be generic since we use them to generate table headers for events.
 *
 * For example:
 * Market: Set {!gamenr} - winner
 * Odd: {competitor1}
 *
 * In this case we use Competitor 1 for {$competitor1}, remove {!gamenr} and we will get:
 * Market: Set - winner
 * Odd: Competitor 1
 *
 * This way the market and odd can be applicable for any event of that sport.
 */
export class LiveBettingSpecifiersFormatter {
    private static team1Placeholder = '{$competitor1}'
    private static team2Placeholder = '{$competitor2}'
    private static regexp = /\(?{.+?}\)?/g

    public format(input: string, specifiers: Record<string, string>): string {
        let result = input
        const placeholders = input.match(LiveBettingSpecifiersFormatter.regexp)
        let specifier

        if (placeholders) {
            placeholders.forEach((placeholder) => {
                switch (placeholder) {
                    case LiveBettingSpecifiersFormatter.team1Placeholder:
                        result = result.replace(placeholder, `${i18n.global.t(COMPETITOR)} 1`)
                        break
                    case LiveBettingSpecifiersFormatter.team2Placeholder:
                        result = result.replace(placeholder, `${i18n.global.t(COMPETITOR)} 2`)
                        break
                    default:
                        specifier = placeholder.replace(/[^a-zA-Z0-9_]/g, '')

                        if (_has(specifiers, specifier)) {
                            result = result.replace(placeholder, '')
                            break
                        }
                }
            })
        }
        return result
    }
}
