import AbstractMapper from '@src/mappers/AbstractMapper'
import { Category, OddType, Sport, Tournament, MarketTreeGroup } from '@src/models/struct/Struct'
import { keyBy } from 'lodash-es'
import type { CategoryDto, OutcomeDto, SportDto, TournamentDto, MarketsNodeDto } from '@superbet-group/offer.clients.lib'
import { getOfferLocaleLangForEnv } from '@src/helpers/helpers'

export class CategoryMapper extends AbstractMapper<CategoryDto, Category> {
    createTargetObject(dto: CategoryDto) {
        const category: Category = new Category();

        category.id = parseInt(dto.id, 10)
        category.name = dto.localNames[getOfferLocaleLangForEnv()]
        category.order = dto.order

        return category;
    }
}

export class OddTypeMapper extends AbstractMapper<OutcomeDto, OddType> {
    createTargetObject(dto: OutcomeDto) {
        const oddType: OddType = new OddType();

        oddType.id = dto.id
        oddType.symbol = dto.columnName

        return oddType;
    }
}

export class SportMapper extends AbstractMapper<SportDto, Sport> {
    createTargetObject(dto: SportDto) {
        const sport: Sport = new Sport();

        sport.id = parseInt(dto.id, 10)
        sport.name = dto.localNames[getOfferLocaleLangForEnv()]
        sport.order = dto.order

        return sport;
    }
}
export class TournamentMapper extends AbstractMapper<TournamentDto, Tournament> {
    createTargetObject(dto: TournamentDto) {
        const tournament: Tournament = new Tournament();

        tournament.id = parseInt(dto.id, 10)
        tournament.name = dto.localNames[getOfferLocaleLangForEnv()]
        tournament.order = dto.order
        tournament.orderPromoted = dto.orderPromoted
        tournament.footer = dto.footer

        return tournament;
    }
}


export class MarketTreeMapper extends AbstractMapper<MarketsNodeDto, MarketTreeGroup> {
    createTargetObject(dto: MarketsNodeDto) {
        const marketTreeGroup: MarketTreeGroup = new MarketTreeGroup()

        const markets = dto.markets.map((market) => {
            const marketId = parseInt(market.marketId, 10)
            return {
                marketId,
                odds: market.outcomes,
            }
        })

        marketTreeGroup.sportId = dto.sportId
        marketTreeGroup.markets = keyBy(markets, 'marketId')

        return marketTreeGroup;
    }
}
