import { OfferType } from '@src/models/enums'
import { MarketAttribute, OddStatus } from '@src/services/enums'

export default class Odd {
    id: number
    uuid: string
    value: number
    name: string
    marketId: number
    marketName: string
    prefix: string
    // Egalite, etc.
    description: string
    isFinished: boolean
    isShownOnOffer: boolean // Visible odds have statuses: active or block
    tags?: string
    marketUuid: string
    //  1, X, 2, 1X, X2, 12....
    symbol: string
    marketOriginalName: string
    marketTag?: string
    order: number
    status: string
    marketOrder: number
    // 1: preEvent , 2: live
    offerType: OfferType
    code: string
    groupId: number
    typeName?: string
    isDropped?: boolean
    initialMarketName?: string;

    /**
     * Every match can have handicap market where you can place bets on regulars odds (1, X, 2) but with
     * handicap (handicap means advantage for some team and the whole purpose is to make betting more interesting)
     * Handicap is given to underdog team.  For example, if Barca plays with Dinamo, bookmakers give handicap to Dinamo
     * 1.5, and id you place bet on Barcelona to win, Barcelona must score +2 goals to beat 1.5 handicap.  Handicap
     * is advantage.  Handicap comes with + or -.
     *
     * odd has uniqueId as eventId oddId specialBetValue because we can have the same handicap market but with different
     * specialBetValues
     *
     * handicap(-1.5) 1 X 2
     * handicap(-2.5) 1 X 2
     * handicap(-3.5) 1 X 2
     */
    specialBetValue: string
    showSpecialBetStatus?: string
    specifiers?: Record<string, string>

    // the id isn't unique per market. To have a truly unique value the id has to be combined with the special bet value
    get uniqueId() {
        return `${this.id} ${this.specialBetValue}`
    }

    public getUniqueMarketId(): string {
        if (this.specialBetValue) {
            return `${this.marketUuid} ${this.specialBetValue}`
        }

        return this.marketId.toString()
    }

    public getMarketId(): string {
        if (this.specialBetValue) {
            return `${this.marketId} ${this.specialBetValue}`
        }

        return this.marketId.toString()
    }

    public getMarketName(): string {
        return this.marketName
    }

    public getUniqueMarketName(): string {
        if (this.specialBetValue && this.showSpecialBetValueInMarketHeader()) {
            return `${this.marketName} ${this.specialBetValue}`
        }

        return this.marketName
    }

    public showSpecialBetValueInMarketHeader(): boolean {
        return this.showSpecialBetStatus === '1'
    }

    public isMain() {
        return !!this.marketTag && this.marketTag.includes(MarketAttribute.main)
    }

    public isMarketType(marketName: string) {
        return (this.tags && this.tags.includes(marketName)) || false
    }

    get isBlocked(): boolean {
        return this.status === OddStatus.block
    }
}
