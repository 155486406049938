/* eslint-disable no-unused-vars */
import AbstractMapper from '@src/mappers/AbstractMapper'
import type MatchResult from '@src/models/MetaData'
import { type Score } from '@src/models/MetaData'
import type { MatchResultDto, MetaDataDto } from '@superbet-group/offer.clients.lib'

interface MapperResult {
    score: MatchResult[]
    isGoingToNextRound: null | 1 | 2
    winner: null | 1 | 2
    finalScore: null | Score // final score is for football number of goals per team, tennis sets per team
    totalPoints: null | number
    result: null | Score // total point when point are separated into groups. e.g. tennis, number of gems
}

interface Input {
    matchResults?: MatchResultDto[];
    metaData?: MetaDataDto;
}


function parseValue(value: string): Score {
    const score = value.split(':').map((x) => x.replace(/\D/g, ''))
    const team1Score = parseInt(score[0], 10)
    const team2Score = parseInt(score[1], 10)

    return {
        team1Score: Number.isNaN(team1Score) ? '-' : team1Score,
        team2Score: Number.isNaN(team2Score) ? '-' : team2Score
    }
}

export class MatchResultMapper extends AbstractMapper<Input, MapperResult> {
    createTargetObject({ matchResults, metaData }: Input): MapperResult {
        const mapperResult: MapperResult = {
            score: [],
            isGoingToNextRound: null,
            winner: null,
            finalScore: null,
            totalPoints: null,
            result: null
        }

        if (matchResults) {
            matchResults.forEach((result, index) => {
                let i

                if ((i = `${ result.value }`.search('')) !== -1) {
                    if (i === 0) {
                        mapperResult.isGoingToNextRound = 1 // first team is going to next round
                    } else {
                        mapperResult.isGoingToNextRound = 2 // second team is going to next round
                    }
                }

                if (index === matchResults.length - 1) {
                    mapperResult.score.push({
                        name: result.name,
                        value: null
                    })

                    return
                }

                mapperResult.score.push({
                    name: result.name,
                    value: parseValue(result.value)
                })
            })
            const firstElement = matchResults[0]

            if (firstElement) {
                mapperResult.result = parseValue(firstElement.value)
            }

            const lastElement = matchResults[matchResults.length - 1]

            if (lastElement) {
                // eslint-disable-next-line eqeqeq
                if (lastElement.value == '1' || lastElement.value == '2') {
                    mapperResult.winner = parseInt(
                        matchResults[matchResults.length - 1].value,
                        10
                    ) as 1 | 2
                } else if (lastElement.value && typeof lastElement.value === 'string') {
                    mapperResult.finalScore = parseValue(lastElement.value as string);
                } else if (typeof lastElement.value === 'number') {
                    mapperResult.totalPoints = lastElement.value
                }
            }
        }

        const isEmpty =
            !matchResults ||
            mapperResult.score.every((score) => !score.value || score.value.team1Score === '-')

        // if match results are empty
        if (isEmpty && metaData) {
            const { homeTeamScore: team1Score, awayTeamScore: team2Score } = metaData

            if (team1Score !== undefined && team2Score !== undefined) {
                if (!mapperResult.score[0]) {
                    mapperResult.score = [
                        {
                            name: 'results.header.final-result',
                            value: {
                                team1Score: parseInt(team1Score, 10),
                                team2Score: parseInt(team2Score, 10)
                            }
                        }
                    ]
                }

                mapperResult.score[0].value = {
                    team1Score: parseInt(team1Score, 10),
                    team2Score: parseInt(team2Score, 10)
                }
                mapperResult.result = {
                    team1Score: parseInt(team1Score, 10),
                    team2Score: parseInt(team2Score, 10)
                }
            }
        }

        return mapperResult;
    }
}
