import '@src/styles/index.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import { CaptureConsole } from '@sentry/integrations'
import App from '@src/App.vue'
import i18n from '@src/i18n'

const app = createApp(App)

Sentry.init({
    app,
    autoSessionTracking: true,
    ignoreErrors: [],
    dist: 'production',
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [new CaptureConsole({ levels: ['error'] })],
    tracesSampleRate: 0,
    sampleRate: 0.1
})

app.use(createPinia())

app.use(i18n)
app.mount('#app')
