import { get as _get } from 'lodash-es'
import Struct, { Category, Sport, Tournament } from '@src/models/struct/Struct'
import Match from '@src/models/Match'
import Odd from '@src/models/Odd'
import { LiveBettingSpecifiersFormatter } from '@src/services/_private/LiveBettingSpecifiersFormatter'

export class StructManager {
    private static instance: StructManager
    private struct: Struct
    private specifierFormatter: LiveBettingSpecifiersFormatter =
        new LiveBettingSpecifiersFormatter()

    private constructor(struct: Struct) {
        this.struct = struct
    }

    public static getInstance(): StructManager {
        if (!StructManager.instance) {
            console.error('Instance is unavailable. Are you sure createInstance was called?')
        }

        return StructManager.instance
    }

    public static isInitialized(): boolean {
        return StructManager.instance !== undefined;
    }

    public static createInstance(struct: Struct) {
        if (!StructManager.instance) {
            StructManager.instance = new StructManager(struct)
        }

        return StructManager.instance
    }

    public denormalizeEvent(event: Match) {
        event.categoryName = this.getCategoryName(event.categoryId)
        event.sportName = this.getSportName(event.sportId)
        event.tournamentName = this.getTournamentName(event.tournamentId)

        try {
            if (!event.sportOrder) {
                event.sportOrder = this.struct.sports[event.sportId].order!
                event.tournamentOrder = this.struct.tournaments[event.tournamentId].order!
                event.specialTournamentOrder = this.struct.tournaments[event.tournamentId].orderPromoted!
            }
        } catch (e) {
            event.validStruct = false
            return
        }

        const oddsValid = this.denormalizeOdds(event)

        event.validStructEvent = !!event.sportName && !!event.categoryName && !!event.tournamentName

        event.validStruct = oddsValid && event.validStructEvent
    }

    public denormalizeEvents(events: Match[]) {
        events.forEach((event) => this.denormalizeEvent(event))
    }

    /**
     * Returns true if fields in odd have all been denormalized successfully.
     */
    public denormalizeOdd(odd: Odd): boolean {
        const { marketOriginalName, marketName } = this.getOddMarketNameWithFallback(odd);

        odd.marketOriginalName = marketOriginalName
        odd.marketName = marketName

        if(!odd.description){
            odd.description = this.specifierFormatter
            .format(this.getOddDescription(odd.id), odd.specifiers!)
            .trim()
        }
       
        odd.symbol = this.specifierFormatter.format(this.getOddSymbol(odd.id), odd.specifiers!)

        return !!odd.marketOriginalName && !!odd.marketName && !!odd.symbol
    }

    private getOddMarketNameWithFallback(
        odd: Odd,
    ): { marketOriginalName: string; marketName: string } {
        if (odd.initialMarketName) {
            return { marketOriginalName: odd.initialMarketName, marketName: odd.initialMarketName };
        } else {
            const marketOriginalName = this.getMarketName(odd.marketId)
            const marketName = this.specifierFormatter.format(
                    this.getMarketName(odd.marketId),
                    odd.specifiers!
                )

            return { marketOriginalName, marketName }
        }
    }

    public denormalizeOdds(event: Match): boolean {
        let isValid = true

        event.getOdds().forEach((odd: Odd) => {
            const result = this.denormalizeOdd(odd)

            if (!result) {
                isValid = false
            }
        })

        return isValid
    }

    public getSportName(id: number | undefined): string {
        return this.getValueFromStruct(id, `sports[${id}].name`)
    }

    public getCategoryName(id: number | undefined): string {
        return this.getValueFromStruct(id, `categories[${id}].name`)
    }

    public getMarketName(id: number | undefined): string {
        return this.getValueFromStruct(id, `markets[${id}].name`)
    }

    public getTournamentName(id: number | undefined): string {
        return this.getValueFromStruct(id, `tournaments[${id}].name`)
    }

    public getOddSymbol(oddId: number | undefined) {
        return this.getValueFromStruct(oddId, `oddTypes[${oddId}].symbol`)
    }

    public getOddDescription(oddId: number | undefined) {
        return this.getValueFromStruct(oddId, `oddTypes[${oddId}].description`)
    }

    private getValueFromStruct(
        id: number | undefined,
        path: string,
        defaultValue = ''
    ) {
        if (!this.struct) {
            throw new Error('Struct manager not properly configured. Struct is missing.')
        }

        if (!id) {
            return ''
        }

        const value = _get(this.struct, path, defaultValue)


        return value
    }

    public setStruct(struct: Struct) {
        this.struct = struct
    }

    public updateSport(sport: Sport) {
        this.struct.sports[sport.id] = sport
    }

    public updateCategory(category: Category) {
        this.struct.categories[category.id] = category
    }

    public updateTournament(tournament: Tournament) {
        this.struct.tournaments[tournament.id] = tournament
    }
}

export default StructManager
