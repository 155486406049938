import { getSportSectionId } from '@src/helpers/helpers'
import type Match from '@src/models/Match'
import i18n from '@src/i18n'

export default class SportSection {
    sportId: number
    sportName: string
    events: Readonly<Match>[]
    isSuperLive: boolean

    private constructor({
        sportId,
        sportName,
        event,
        isSuperLive
    }: {
        sportId: number
        sportName: string
        event: Readonly<Match>
        isSuperLive: boolean
    }) {
        this.sportId = sportId
        this.sportName = sportName
        this.events = [event]
        this.isSuperLive = isSuperLive
    }

    public static createFromEvent(event: Readonly<Match>) {
        return new SportSection({
            sportId: event.sportId,
            sportName: event.sportName,
            event,
            isSuperLive: event.isSuperLive
        })
    }

    get uniqueId(): string {
        return getSportSectionId(this.sportId, this.isSuperLive)
    }

    get uniqueName(): string {
        return this.isSuperLive ? `${i18n.global.t('Top Live')} ${this.sportName}` : this.sportName
    }
}
