import { defineStore } from 'pinia'
import { isEmpty } from 'lodash-es'
import i18n from '@src/i18n'
import { Subscriptions } from '@src/models/Subscriptions'
import type Match from '@src/models/Match'
import { type LiveOfferState, type DisplayMarkets, DisplayMarket } from '@src/stores/types'
import SportSection from '@src/models/SportSection'
import { mergeMatch, freezeOdds, getStartDateForLive } from '@src/helpers/helpers'
import { filterEvents, isInDisplayRange, sortEvents, sseHandler } from '@src/stores/helpers'
import { VISIBLE_EVENTS_START, VISIBLE_EVENTS_END, NUMBER_OF_VISIBLE_MARKETS } from '@src/constants/constants'
import sportMarkets from '@src/constants/sportMarkets.json'
import OfferService from '@src/services/OfferService'
import StructService from '@src/services/StructService'
import StructManager from '@src/services/StructManager'
import { SSEStructType } from '@superbet-group/offer.clients.lib'
import type { SSEEventMessage, SSEStructMessage } from '@src/models/SSEMessage'
import type { Category, Sport, Tournament } from '@src/models/struct/Struct'

export const useLiveOfferStore = defineStore('liveOffer', {
    state: (): LiveOfferState => {
        return {
            events: [], 
            isLiveOfferFetched: false, 
            structTimestamps: {}
        } 
    },
    getters: {
        getEventIndex(state: LiveOfferState) {
            return (eventId: number): number => {
                return state.events.findIndex((event) => event.id === eventId)
            }
        },
        // Groups events by sport
        // Super live events are grouped separately by sport and prefixed with superLive
        eventsBySport(state: LiveOfferState): SportSection[] {
            const events = state.events.slice(VISIBLE_EVENTS_START, VISIBLE_EVENTS_END)

            const eventsBySport: SportSection[] = []

            events.forEach((event) => {
                // Check if the sport is already in the array
                const sportIndex = eventsBySport.findIndex(
                    (sport) =>
                        sport.sportId === event.sportId && event.isSuperLive === sport.isSuperLive
                )

                if (sportIndex > -1) {
                    eventsBySport[sportIndex].events.push(event)
                } else {
                    const sportEvent = SportSection.createFromEvent(event)

                    eventsBySport.push(sportEvent)
                }
            })
            return eventsBySport
        },
        // Group markets and odd symbols per sport
        // Super live are grouped separately by sport and prefixed with superLive
        displayMarkets() {
            const markets: DisplayMarkets = {}

            this.eventsBySport.forEach((sport) => {
                const predefinedMarket = sportMarkets[sport.sportId]
                const marketsBySport: DisplayMarket[] = []

                // Sport has predefined markets
                if (predefinedMarket) {
                    predefinedMarket.forEach((market) => {
                        const displayMarket = new DisplayMarket({
                            marketId: market.marketId,
                            marketName: i18n.global.t(`markets.${market.marketName}`),
                            sportId: sport.sportId
                        })

                        market.odds.forEach((odd) => {
                            displayMarket.addOdd({
                                id: odd.id,
                                symbol: i18n.global.t(odd.symbol)
                            })
                        })

                        marketsBySport.push(displayMarket)
                    })
                    markets[sport.uniqueId] = marketsBySport
                }
                // Sport does not have predefined markets, generate them from an event
                else {
                    // First event in sport with odds to use for extracting markets
                    const sportMarketsRef = sport.events.find((event) => !isEmpty(event.odds))

                    if (sportMarketsRef) {
                        for (const odd of sportMarketsRef.odds) {
                            // Check if market for the odd is already in the array
                            const marketIndex = marketsBySport.findIndex(
                                (market) => market.marketId === odd.marketId
                            )

                            if (marketIndex > -1) {
                                // Skip over odd of the same market with a different specifier from stored in marketsBySport
                                if (
                                    odd.getMarketId() !== marketsBySport[marketIndex].marketUniqueId
                                ) {
                                    continue
                                }

                                marketsBySport[marketIndex].addOdd({
                                    id: odd.id,
                                    symbol: odd.symbol
                                })
                            } else {
                                if (marketsBySport.length >= NUMBER_OF_VISIBLE_MARKETS) {
                                    break
                                }

                                const market = new DisplayMarket({
                                    marketId: odd.marketId,
                                    marketName: odd.marketName,
                                    sportId: sportMarketsRef.sportId,
                                    marketUniqueId: odd.getMarketId()
                                })

                                market.addOdd({ id: odd.id, symbol: odd.symbol })

                                marketsBySport.push(market)
                            }
                        }
                        markets[sport.uniqueId] = marketsBySport
                    }
                }
            })
            return markets
        }
    },
    actions: {
        setEvents(events: Match[]) {
            events.forEach((event) => {
                const hasEvent = !!this.events.find((e) => e.id === event.id)

                if (hasEvent) {
                    this.updateEvent(event)
                } else {
                    this.addEvent(event)
                }
            })
        },
        // Handles subscribing and unsubscribing from the displayed events
        eventsSubscriptionHandler(event: Match | Readonly<Match>) {
            // Subscribe to event that got moved into display
            this.subscribeToEvent(event.id)

            const displayEventIds = this.events.slice(VISIBLE_EVENTS_START, VISIBLE_EVENTS_END).map(event => event.id)
            const subscriptionIds = Subscriptions.getSubscriptionIds()

            // Unsubscribe from event that got moved out of display
            subscriptionIds.forEach(id => {
                if(!displayEventIds.find(eventId => id === `${eventId}`)) {
                    Subscriptions.removeSubscription(Number(id))
                }
            })
        },
        addEvent(event: Match) {
            freezeOdds(event)

            // Find at which index to insert new event
            const filteredEvents = filterEvents([...this.events, event])
            const sortedEvents = sortEvents(filteredEvents)
            const eventIndex = sortedEvents.findIndex((e) => e.id === event.id)

            // Event was not found so we don't need to add it as it was filtered out
            if(eventIndex === -1){
                return;
            }

            this.events.splice(eventIndex, 0, Object.freeze(event))

            // New event is in display
            if (isInDisplayRange(eventIndex)) {
                this.eventsSubscriptionHandler(event)
            // New event pushes existing into display
            } else if (eventIndex < VISIBLE_EVENTS_START) {
                const subscribeEvent = this.events[VISIBLE_EVENTS_START]

                if(subscribeEvent) {
                    this.eventsSubscriptionHandler(subscribeEvent)
                }
            }
        },
        updateEvent(event: Match) {
            freezeOdds(event)
            const oldIndex = this.getEventIndex(event.id)

            // Find possible new index for event
            const filteredEvents = filterEvents([...this.events, event])
            const sortedEvents = sortEvents(filteredEvents)
            const newIndex = sortedEvents.findIndex((e) => e.id === event.id)

            // Event was not found in the sorted events so it means we must remove it
            if(newIndex === -1){
                this.removeEvent(event.id.toString())
                return;
            }

            // Event changed positions
            if (oldIndex !== newIndex) {
                // Move event to new index
                const oldEvent = this.events.splice(oldIndex, 1)[0]
                this.events.splice(newIndex, 0, mergeMatch(oldEvent, event))

                // Event moved to display
                if (isInDisplayRange(newIndex)) {
                    this.eventsSubscriptionHandler(event)
                }
                // Event moved out of display 
                else if (isInDisplayRange(oldIndex)) {
                    // Event moved either under or above display range
                    const subscribeEvent = newIndex < VISIBLE_EVENTS_START ? this.events[VISIBLE_EVENTS_START] : this.events[VISIBLE_EVENTS_END - 1]

                    // Subscribe to event that got moved into display
                    this.eventsSubscriptionHandler(subscribeEvent)
                }
            } else {
                this.events[newIndex] = mergeMatch(this.events[newIndex], event)
            }
        },
        removeEvent(eventId: string) {
            const eventIndex = this.getEventIndex(Number(eventId))
            const removedEvent = this.events.splice(eventIndex, 1)[0]

            // Removed event shifted displayed events
            if (eventIndex < VISIBLE_EVENTS_END) {
                // Event was either displayed or shifted displayed event
                const unsubscribeEventId = isInDisplayRange(eventIndex) ? removedEvent.id : this.events[VISIBLE_EVENTS_START - 1]?.id
                Subscriptions.removeSubscription(unsubscribeEventId)

                // Subscribe to event that got moved into display
                const subscribeEvent = this.events[VISIBLE_EVENTS_END - 1]

                if (subscribeEvent) {
                    this.eventsSubscriptionHandler(subscribeEvent)
                }
            }
        },
        // Subscribe to all events with preselected odds only
        subscribeToLiveEventChanges() {
            const callback = (events: SSEEventMessage[]) => {
                let isRestSyncTriggered = false
                events.forEach(({ event, resourceId, timestamp }) => {
                    // Check if we have the event in our local state and that we don't have an active subscription to it
                    // If we have the subscription, it will handle updating the event
                    if (!Subscriptions.hasSubscription(Number(resourceId))) {
                        const addHandler = (event: Match) => {
                            this.addEvent(event)
                        }
                        const fetchHandler = () => {
                            if(!isRestSyncTriggered) {
                                this.fetchLiveEvents()
                                isRestSyncTriggered = true
                            }
                        }
                        const updateHandler = (event: Match) => {
                            this.updateEvent(event)
                        }
                        const removeHandler = () => {
                            this.removeEvent(resourceId)
                        }
                        const eventIndex = this.getEventIndex(Number(resourceId))

                        const errorMessage =
                            'Something unknown happened while updating all live events from sse message'

                        sseHandler(
                            event,
                            resourceId,
                            timestamp,
                            this.events[eventIndex],
                            addHandler,
                            fetchHandler,
                            updateHandler,
                            removeHandler,
                            errorMessage
                        )
                    }
                })
            }
            OfferService.getInstance().subscribeToAllLiveEventChanges(callback)
        },
        // Subscribe to an event with all odds
        subscribeToEvent(eventId: number) {
            if(!Subscriptions.hasSubscription(eventId)) {
                const callback = async ({ event, resourceId, timestamp }) => {
                    const fetchHandler = async () => {
                        const events = await OfferService.getInstance().getEventsById([ Number(resourceId)])

                        this.setEvents(events)
                    }
                    const updateHandler = (event: Match) => {
                        this.updateEvent(event)
                    }

                    const removeHandler = () => {
                        this.removeEvent(resourceId)
                    }

                    const eventIndex = this.getEventIndex(Number(resourceId))

                    const errorMessage =
                        'Something unknown happened while updating single event from sse message'

                    sseHandler(
                        event,
                        resourceId,
                        timestamp,
                        this.events[eventIndex],
                        () => {},
                        fetchHandler,
                        updateHandler,
                        removeHandler,
                        errorMessage
                    )
                }
                const subscription = OfferService.getInstance().subscribeToEventChanges(eventId, callback)
                Subscriptions.addSubscription(eventId, subscription)
            }
        },
        async fetchLiveEvents() {
            const startDate = getStartDateForLive()

            try {
                const events = await OfferService.getInstance().getLiveEvents(startDate)

                const filteredEvents = filterEvents(events)
                const sortedEvents = sortEvents(filteredEvents)

                this.setEvents(sortedEvents)
                this.isLiveOfferFetched = true
            } catch (e) {
                console.error('Error fetching live events:', e)
            }
        },

        async fetchStruct() {
            const struct = await StructService.getInstance().getStruct()

            if(!StructManager.isInitialized()){
                StructManager.createInstance(struct)
            } else {
                StructManager.getInstance().setStruct(struct)
            }
            
        },
        subscribeToStructChanges() {
            OfferService.getInstance().subscribeToStructChanges(async (events: SSEStructMessage[]) => {
                let shouldRefetchStruct = false
                let shouldRefetchOffer = false
                events.forEach((event) => {
                    if(Object.keys(this.structTimestamps).length === 0) {
                        this.structTimestamps[event.resourceId] = event.timestamp

                        //Ignore first message, as we need to get the first timestamp
                        return
                    } 

                    if(event.timestamp > this.structTimestamps[event.resourceId] || !this.structTimestamps[event.resourceId]) {
                        if(!shouldRefetchOffer){
                            shouldRefetchOffer = true
                        }
                        this.structTimestamps[event.resourceId] = event.timestamp
                        if(!event.data){
                            if(!shouldRefetchStruct){
                                //We didn't get update data so we must fetch the struct again
                                shouldRefetchStruct = true
                            }
                        } else {
                            //We get the update data so we update the struct with the new sport/category/tournament
                            switch(event.type) {
                                case SSEStructType.Sport:
                                    StructManager.getInstance().updateSport(event.data as Sport)
                                    break
                                case SSEStructType.Category:
                                    StructManager.getInstance().updateCategory(event.data as Category)
                                    break
                                case SSEStructType.Tournament:
                                    StructManager.getInstance().updateTournament(event.data as Tournament)
                                    break
                            }
                        }        
                    }
                })

                if(shouldRefetchStruct) {
                    await this.fetchStruct()
                }
                if(shouldRefetchOffer) {
                    //Refresh all events with new struct data as the current events are marked as readonly
                    this.fetchLiveEvents()
                }
            })
        },
    }
})
