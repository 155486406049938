<script setup lang="ts">
import type { MetaData } from '@src/models/MetaData'

const props = defineProps<{
    hasSets: boolean
    metaData?: MetaData
    matchStatus: string
    team1Name: string
    team2Name: string
    team1Score: string
    team2Score: string
}>()
</script>

<template>
    <div class="event-minute font-number">{{ props.matchStatus }}</div>
    <div class="event-status">
        <div class="event-status__teams">
            <div class="team">
                <span class="team__name">{{ props.team1Name }}</span>
                <i v-if="metaData?.serve === 1" class="superbet-icon icon-serve" />
            </div>
            <div class="team">
                <span class="team__name">{{ props.team2Name }}</span>
                <i v-if="metaData?.serve === 2" class="superbet-icon icon-serve" />
            </div>
        </div>
        <div class="event-status__score font-number">
            <template v-if="hasSets">
                <template v-for="(period, index) in metaData?.periods" :key="index">
                    <span class="team-score">{{ period.team1Score }}</span>
                    <span class="team-score">{{ period.team2Score }}</span>
                </template>
            </template>
            <template v-else>
                <span class="team-score">{{ props.team1Score }}</span>
                <span class="team-score">{{ props.team2Score }}</span>
            </template>
        </div>
    </div>
</template>

<style scoped lang="scss">
.event-minute {
    flex: 0 0 2.69%;
    font-size: $font-size-m;
    text-align: center;
    margin: $space-base;
}

.event-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bolder;
    letter-spacing: $table-row-event-letter-spacing;
    flex: 0 0 15.78%;
    max-width: 15.78%;

    &__teams {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .team {
            display: flex;
            align-items: center;
            max-width: min-content;

            &__name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .icon-serve {
                padding-left: $space-s;
                font-size: $font-size-xl;
                color: $yellow;
            }
        }
    }

    &__score {
        justify-content: center;
        align-content: flex-end;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: $table-row-height;
        color: $orange;
        flex: 0 0 auto;
        margin: 0 $space-base 0 $space-l;
        line-height: 1.75;

        .team-score {
            padding: 0 $space-xxs;
            text-align: center;
        }
    }
}
</style>
