import { orderBy } from 'lodash-es'
import Match from '@src/models/Match'
import { VISIBLE_EVENTS_START, VISIBLE_EVENTS_END } from '@src/constants/constants'

export const sseHandler = (
    event: Match | null,
    resourceId: string,
    timestamp: number,
    localEvent: Readonly<Match>,
    addHandler: (event: Match) => void,
    fetchHandler: () => void,
    updateHandler: (event: Match) => void,
    removeHandler: () => void,
    errorMessage: string
) => {
    // Event is finished
    if (event && event.isFinished()) {
        removeHandler()

        return
    }

    //we received event data but event is not in our local store we must add it
    //else if event isn't in our store and there was no event data, fetch the offer manually
    if (!localEvent && !!event) {
        addHandler(event)

        return
    } else if (!localEvent && !event) {
        fetchHandler()

        return
    }

    // Local data is newer or same as the one in sse message
    if (timestamp <= localEvent.matchTimestamp!) {
        return
    }

    // Local data is older, but we don't have event data available, we must fetch it manually
    if (timestamp > localEvent.matchTimestamp! && !event) {
        fetchHandler()

        return
    }

    //Local data is older, and we need to update with the event data in the sse message
    if (!!event && timestamp > localEvent.matchTimestamp!) {
        updateHandler(event)
    } else {
        console.error('Error:', errorMessage, 'on:', resourceId)
    }
}

export const sortEvents = <T extends Match[] | Readonly<Match>[]>(events: T): T => {
    const sortOrder = [
        'isSuperLive',
        'sportOrder',
        'specialTournamentOrder',
        'categoryName',
        'tournamentOrder',
        'tournamentName2',
        'date',
        'code',
        'id'
    ]

    // Order isSuperLive by descending, rest ascending
    const order: ('asc' | 'desc')[] = new Array(sortOrder.length).fill('asc')
    order[0] = 'desc'

    const sortedEvents = orderBy(events, sortOrder, order) as unknown as T
    return sortedEvents
}

export const filterEvents = <T extends Match | Readonly<Match>>(events: T[]): T[] => {
    return events.filter((event) => event.validStruct && event.isOngoing())
}

export const isInDisplayRange = (index: number): boolean => {
    return (index >= VISIBLE_EVENTS_START && index < VISIBLE_EVENTS_END)
}
