/* eslint-disable no-useless-escape */
import Match from '@src/models/Match'
import AbstractMapper from '@src/mappers/AbstractMapper'
import { MatchResultMapper } from '@src/mappers/MatchResultMapper'
import { OddMapper } from '@src/mappers/OddMapper'
import { MetaDataMapper } from '@src/mappers/MetaDataMapper'
import { OfferStatus, OfferType } from '@src/models/enums'
import { type EventDto } from '@superbet-group/offer.clients.lib'

const metaDataMapper: MetaDataMapper = new MetaDataMapper()
const oddMapper: OddMapper = new OddMapper()
const matchResultMapper: MatchResultMapper = new MatchResultMapper()

export class EventMapper extends AbstractMapper<EventDto, Match>{
    createTargetObject(dto: EventDto) {
        const e: Match = new Match()

        const isLiveEvent = dto.offerStateStatus[OfferType.live] === OfferStatus.active

        e.id = dto.eventId
        e.uuid = dto.uuid
        e.offerId = dto.offerId
        e.specialOfferId = dto.specialOfferId
            ? parseInt(dto.specialOfferId.replace(/[^0-9\.]+/g, ''), 10)
            : null
        e.fullName = dto.matchName
        e.sportId = dto.sportId
        e.tournamentId = dto.tournamentId
        e.date = this.toDate(dto.utcDate) as Date
        e.matchTimestamp = dto.matchTimestamp

        e.oddCount = isLiveEvent ? dto.counts.odds[OfferType.live] : 0
        e.team1Id = parseInt(dto.homeTeamId, 10)
        e.team2Id = parseInt(dto.awayTeamId, 10)
        e.incrementId = dto.incrementId
        e.categoryId = dto.categoryId
        e.offerStatus = dto.offerStateStatus
        e.code = dto.matchCode

        e.metaData = dto.metadata ? metaDataMapper.map(dto.metadata, false) : undefined
        e.matchPromotedLive = dto.matchPromotedLiveOrder
        e.tournamentPromotedLive = dto.tournamentPromotedLiveOrder
        e.matchTags = dto.matchTags ? dto.matchTags.split(',') : []

        const odds = (dto.odds || []).filter((o) => o.offerStateId === OfferType.live)

        e.setOdds(oddMapper.map(odds, true))

        e.setOddResults(dto.oddsResults ? oddMapper.map(dto.oddsResults!, true) : [])

        e.matchResults = matchResultMapper.createTargetObject({
            matchResults: dto.matchResults,
            metaData: dto.metadata
        })
    
        return e
    }
}
