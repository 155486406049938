<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
    sportId: number
    uniqueName: string
    isSuperLive: boolean
}>()

const headerIcon = computed(() => {
    return props.isSuperLive ? 'promotii' : `sport-${props.sportId}`
})
</script>

<template>
    <div class="sport-column">
        <i :class="['superbet-icon', `icon-${headerIcon}`]" />
        {{ props.uniqueName }}
    </div>
</template>

<style scoped lang="scss">
.sport-column {
    display: inherit;
    flex: 0 1 21.81%;
    max-width: 21.81%;
    margin: 0 $space-xxl 0 $space-xxs;
    align-self: center;
    text-align: left;
    font-size: $font-size-xl;
    font-weight: $font-weight-boldest;

    i {
        margin-right: $space-s;
    }
}
</style>
