<script setup lang="ts">
import SportHeaderColumn from '@src/components/SportHeaderColumn.vue'
import MarketHeaderColumn from '@src/components/MarketHeaderColumn.vue'
import type SportSection from '@src/models/SportSection'

const props = defineProps<{
    sport: SportSection
}>()
</script>

<template>
    <div class="table-header">
        <SportHeaderColumn
            :sport-id="props.sport.sportId"
            :unique-name="props.sport.uniqueName"
            :is-super-live="props.sport.isSuperLive"
        />
        <MarketHeaderColumn :id="props.sport.uniqueId" />
    </div>
</template>

<style scoped lang="scss">
.table-header {
    width: 100%;
    display: inline-flex;
    text-transform: uppercase;
    margin-bottom: $space-s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
