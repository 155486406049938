<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useLiveOfferStore } from '@src/stores/offer'

const store = useLiveOfferStore()
const { displayMarkets } = storeToRefs(store)

const props = defineProps<{
    id: string
}>()
</script>

<template>
    <div v-for="market in displayMarkets[props.id]" :key="market.marketId" class="market-column">
        {{ market.marketName }}
        <div class="market-column__symbols">
            <div v-for="(odd, index) in market.odds" :key="index" class="symbol">
                {{ odd?.symbol || '-' }}
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.market-column {
    color: $grey;
    text-align: center;
    font-size: $font-size-l;
    font-weight: $font-weight-boldest;
    flex: 0 0 13.79%;
    padding: 0 $space-l;
    margin: 0 $space-sm;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &__symbols {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-weight: $font-weight-bolder;

        .symbol {
            flex: 0 0 27.92%;
        }
    }
}
</style>
