import { get as _get } from 'lodash-es'
import Odd from '@src/models/Odd'
import { OfferStatus, OfferType, SetSports } from '@src/models/enums'
import { MarketAttribute, MatchTags } from '@src/services/enums'
import { Market } from '@src/models/struct/Struct'
import { type MetaData, type Score } from '@src/models/MetaData'
import type MatchResult from '@src/models/MetaData'
import { COMPETITOR_DELIMITER } from '@src/constants/constants'

export default class Match {
    id: number
    uuid: string
    overrideUuid?: string
    offerId: number
    specialOfferId: number | null
    fullName: string
    sportId: number
    sportName: string
    tournamentId: number
    tournamentName: string
    date: Date
    oddCount?: number
    matchTimestamp?: number
    team1Id: number
    team2Id: number

    // shop code
    code: number

    matchResults: {
        isGoingToNextRound: null | 1 | 2
        winner: null | 1 | 2
        finalScore: null | Score
        totalPoints: null | number
        score: MatchResult[]
        result: null | Score
    }
    team1PreviousResult: string
    team2PreviousResult: string

    tournamentName2: string

    // every superbet change (on every Match field) is +1
    incrementId: number
    categoryId: number
    categoryName: string
    sportOrder: number
    categoryOrder: number
    tournamentOrder?: number
    specialTournamentOrder?: number
    offerStatus: Record<OfferType, OfferStatus>
    metaData?: MetaData | undefined
    matchTags: string[]

    matchPromotedLive: number
    tournamentPromotedLive: number

    odds: Odd[] = []
    // used when Match has finished
    oddResults: Odd[] = []

    private _team1Name: string
    private _team2Name: string

    /**
     * Indicates if StructManager has denormalized all fields succesfully.
     */
    // only event's fields
    validStructEvent?: boolean
    // event and odd
    validStruct?: boolean

    get team1Name(): string {
        if (this._team1Name) {
            return this._team1Name
        }

        if (!this.fullName) {
            return ''
        }

        const parts = this.fullName.split(COMPETITOR_DELIMITER)

        if (parts.length < 2) {
            console.error(
                new Error(`Match name split error for ${this.id} and name ${this.fullName}`)
            )

            return ''
        }

        return parts[0]
    }

    get team2Name(): string {
        if (this._team2Name) {
            return this._team2Name
        }

        if (!this.fullName) {
            return ''
        }

        const parts = this.fullName.split(COMPETITOR_DELIMITER)

        if (parts.length < 2) {
            console.error(
                new Error(`Match name split error for ${this.id} and name ${this.fullName}`)
            )

            return ''
        }

        return parts[1]
    }

    getPreselectMarket(prematchTag: string = MarketAttribute.main): Market | undefined {
        // there is some duplication with getMarkets, but this method needs to be super fast
        const odds = this.getOdds()
        let market: Market | undefined
        let isPrematchMarket

        if (prematchTag === MarketAttribute.main) {
            isPrematchMarket = (odd: Odd) => odd.isMain()
        }

        for (let i = 0, n = odds.length; i < n; i += 1) {
            const odd = odds[i]

            if (!market) {
                if (isPrematchMarket(odd)) {
                    market = this.createMarket(odd)
                } else {
                    continue
                }
            } else if (!isPrematchMarket(odd)) {
                break
            } else {
                market.odds.push(odd)
            }
        }

        return market!
    }

    public containsFullOddList(): boolean {
        return _get(this, 'oddCount', 0) === this.getOdds().length
    }

    public getOdds(): Odd[] {
        if (this.isFinished() && this.oddResults.length !== 0) {
            return this.oddResults
        }

        return this.odds
    }

    public isFinished(): boolean {
        return _get(this.offerStatus, OfferType.live) === OfferStatus.finished
    }

    public isOngoing(): boolean {
        return _get(this.offerStatus, OfferType.live) === OfferStatus.active
    }

    public setOdds(odds: Odd[]) {
        this.odds = odds
    }

    public appendOdds(odds: Odd[]) {
        odds.forEach((odd) => this.odds.push(odd))
    }

    public setOddResults(odds: Odd[]) {
        this.oddResults = odds
    }

    get isSuperLive() {
        return this.matchTags.includes(MatchTags.superLive)
    }

    get matchStatus(): string {
        const minute = _get(this, 'metaData.minute', undefined)

        if (minute) {
            return `${minute}'`
        }

        const period = _get(this, 'metaData.periodStatus', undefined)

        return period || '-'
    }

    get team1Score(): string {
        const score = _get(this, 'metaData.team1Score', '-')

        return `${score}`
    }

    get team2Score(): string {
        const score = _get(this, 'metaData.team2Score', '-')

        return `${score}`
    }

    get hasSets(): boolean {
        return Object.values(SetSports).includes(this.sportId)
    }

    private createMarket(odd: Odd): Market {
        const market = new Market({
            id: odd.marketId,
            originalName: odd.marketOriginalName,
            uniqueId: odd.getUniqueMarketId(),
            name: odd.getUniqueMarketName(),
            order: odd.marketOrder,
            tags: odd.tags,
            odds: [odd]
        })

        return market
    }
}
