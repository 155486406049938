export const APP_LANG = import.meta.env.VITE_APP_LANG
export const COMPETITOR = 'Competitor'
export const COMPETITOR_DELIMITER = '·'
export const NUMBER_OF_VISIBLE_EVENTS = 10
export const NUMBER_OF_VISIBLE_MARKETS = 5
export const NUMBER_OF_VISIBLE_ODDS_FOR_SPECIFIER = 2
export const ODD_CHANGE_TRACKER_TIMEOUT = 5000
export const SENTRY_TIMEOUT_MESSAGE = 'timeout of 30000ms exceeded'
export const PAGE_PARAM = Number(new URL(location.href).searchParams.get('page'))
export const CURRENT_PAGE = PAGE_PARAM && PAGE_PARAM > 0 ? PAGE_PARAM : 1
export const VISIBLE_EVENTS_START = NUMBER_OF_VISIBLE_EVENTS * (CURRENT_PAGE - 1)
export const VISIBLE_EVENTS_END = VISIBLE_EVENTS_START + NUMBER_OF_VISIBLE_EVENTS
