<script setup lang="ts">
import EventRow from '@src/components/EventRow.vue'
import type SportSection from '@src/models/SportSection'

const props = defineProps<{
    sport: SportSection
}>()
</script>

<template>
    <div :class="`table-body sport-${sport.sportId}`">
        <div v-for="event in props.sport.events" :key="event.uuid" class="table-body__event">
            <EventRow :event="event" :sport-section-id="props.sport.uniqueId" />
        </div>
    </div>
</template>

<style lang="scss">
.table-body {
    display: flex;
    flex-direction: column;
    border-radius: $table-border-radius;
    background: $table-background;

    &.sport-1 {
        background: $table-background-volleyball;
    }
    &.sport-2 {
        background: $table-background-tennis;
    }
    &.sport-4 {
        background: $table-background-basketball;
    }

    &__event {
        display: inline-flex;
        align-items: center;
        height: $table-row-height;
        font-size: $font-size-lg;

        &:nth-of-type(even) {
            background: $table-row-dark-background;

            .event-market {
                background: $table-row-odds-dark-background;
            }
        }
    }
}
</style>
