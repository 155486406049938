import type Match from '@src/models/Match'
import type Odd from '@src/models/Odd'
import StructManager from '@src/services/StructManager'

export class DisplayMarket {
    private marketOdds: number[]
    marketId: number
    marketUniqueId?: string
    marketName: string
    odds: (DisplayMarketOdd | null)[]

    constructor({
        marketId,
        marketName,
        sportId,
        marketUniqueId = ''
    }: {
        marketId: number
        marketName: string
        sportId: number
        marketUniqueId?: string
    }) {
        this.marketId = marketId
        this.marketName = marketName
        this.marketUniqueId = marketUniqueId

        const marketTree = StructManager.getInstance()['struct']['marketTree']
        // Expected odd ids
        this.marketOdds = marketTree[sportId].markets[marketId].odds

        this.odds = new Array(this.marketOdds.length).fill(null)
    }

    addOdd = (odd: DisplayMarketOdd) => {
        const oddIndex = this.marketOdds.findIndex((oddId) => oddId === odd.id)
        this.odds[oddIndex] = odd
    }
}

interface DisplayMarketOdd {
    id: number
    symbol: string
}

export type DisplayMarkets = Record<string, DisplayMarket[]>

export interface LiveOfferState {
    events: Readonly<Match>[]
    isLiveOfferFetched: boolean
    structTimestamps: Record<string, number>
}

export interface DisplayOdds {
    odds: (Odd | null)[]
    specialBetValue: string
}
