import Match from '@src/models/Match'
import { OfferLangLocale } from '@superbet-group/offer.clients.lib'

export const mergeMatch = (matchBefore: Readonly<Match>, matchAfter: Match) => {
    // we receive event update data
    // locally we have the latest event data
    // only when we fetch event we get the full data and store it locally
    if (matchBefore.matchTimestamp === matchAfter.matchTimestamp) {
        // both local data and fetched data are full (all odds), return fetched data
        if (matchBefore.containsFullOddList() && matchAfter.containsFullOddList()) {
            return Object.freeze(matchAfter)
        }

        // local data is full and updated, return local data
        if (matchBefore.containsFullOddList()) {
            return matchBefore
        }

        // fetched data is full, replace local with fetch data
        // local had only final market
        if (matchAfter.containsFullOddList()) {
            return Object.freeze(matchAfter)

            // neither data is full, update local data with fetched data, returned merged data
            // this case covers when there is update on final markets
        } else {
            const afterOdds = matchAfter.getOdds()
            const beforeOdds = matchBefore
                .getOdds()
                .filter((o1) => !afterOdds.some((o2) => o1.uuid === o2.uuid || o1.id === o2.id))

            matchAfter.setOdds([...beforeOdds, ...afterOdds])

            return Object.freeze(matchAfter)
        }
    }

    // we receive data that is newer then local data
    // this is only for events that dont have full data because every time we select event we fetch the latest data
    // flow with new changes for subscriptions: - We have two sockets updates for event that is selected -> 1st in only final market, 2nd is full market list
    // TODO - This should be reworked when we integrate SSE
    //1. we get partial update with final odd markets which triggers the if when matchAfter.incrementId is larger
    // this causes the match data to be updated to latest increment, but odds list is still incomplete
    //2. we get the second update with full odd markets but at that time first if check gets triggered because we updated the match with partial updates
    //3. we go into line 9 of this file and return full updated odds
    if (matchAfter.matchTimestamp! > matchBefore.matchTimestamp!) {
        // legacy part from web, this only updates the final market if local data is full
        if (matchBefore.containsFullOddList() && !matchAfter.containsFullOddList()) {
            const afterOdds = matchAfter.getOdds()

            let beforeOdds = matchBefore
                .getOdds()
                .filter((o1) => !afterOdds.some((o2) => o1.id === o2.id))

            // check if fetched data doesnt include final market
            // filter out final/main market if its no longer in offer (eg. it got disabled)
            if (!matchAfter.getPreselectMarket()) {
                beforeOdds = beforeOdds.filter((odd) => !odd.isMain())
            }

            //merge fetched and local data
            matchAfter.setOdds([...afterOdds, ...beforeOdds])
        }

        return Object.freeze(matchAfter)
    }

    // when we get an outdated event update and we don't have the full list of odds locally,
    // we want to extract missing odds from the update even though they might be outdated
    // It's likely that some of them won't be outdated and that won't break the flow of creating a ticket
    // For odds that are outdated, BA will reject the ticket
    if (
        matchBefore.matchTimestamp! > matchAfter.matchTimestamp! &&
        !matchBefore.containsFullOddList()
    ) {
        const beforeOdds = matchBefore.getOdds()
        const afterOdds = matchAfter
            .getOdds()
            .filter((o1) => !beforeOdds.some((o2) => o1.uuid === o2.uuid || o1.id === o2.id))

        matchBefore.appendOdds(afterOdds)
    }

    return matchBefore
}

export function freezeOdds(match: Match) {
    match.setOdds(match.getOdds().map((odd) => Object.freeze(odd)))
}

function addLeadingZero(time: number): string {
    return `${time}`.padStart(2, '0')
}

// Format date to yyyy-MM-dd HH:mm:ss string
export function dateToString(date: Date): string {
    const year = date.getFullYear()
    const month = addLeadingZero(date.getMonth() + 1)
    const day = addLeadingZero(date.getDate())
    const hour = addLeadingZero(date.getHours())
    const minute = addLeadingZero(date.getMinutes())
    const second = addLeadingZero(date.getSeconds())

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

export function stringToDate(string: string): Date {
    return new Date(string)
}

function isTimezonelessFormat(dateString: string) {
    const regex = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/

    return regex.test(dateString)
}

export function fillToUTCDate(dateString: string) {
    let utcDateString = dateString

    if (isTimezonelessFormat(dateString)) {
        const [date, time] = dateString.split(' ')

        utcDateString = `${date}T${time}Z`
    } else {
        // TODO we need to handle this if we know it can occur
    }

    return utcDateString
}

export function getStartDateForLive(): Date {
    const date = new Date()
    date.setSeconds(0, 0)

    return date
}

export function getSportSectionId(sportId: number, isSuperLive: boolean): string {
    return isSuperLive ? `superLive-${sportId}` : `${sportId}`
}

export function getOfferLocaleLangForEnv(){
    switch (import.meta.env.VITE_APP_COUNTRY) {
        case 'ro':
            return OfferLangLocale.RomanianRomaniaRetail;
        default:
            return OfferLangLocale.RomanianRomaniaRetail;
    }
};
